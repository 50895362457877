<template>
  <div id="disinfectantMakeIssue">
    <el-dialog
      :title="disinfectantMakeIssueFormTitle"
      width="800px"
      :visible.sync="disinfectantMakeIssueDialogVisible"
      :close-on-click-modal="false"
      @close="disinfectantMakeIssueDialogClose"
    >
      <el-form
        ref="disinfectantMakeIssueFormRef"
        :model="disinfectantMakeIssueForm"
        :rules="disinfectantMakeIssueFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="配制日期" prop="makeDate">
              <el-date-picker
                v-model="disinfectantMakeIssueForm.makeDate"
                placeholder="请选择配制日期"
                value-format="yyyy-MM-dd"
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="消毒剂名称" prop="type">
              <el-radio-group
                v-model="disinfectantMakeIssueForm.type"
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              >
                <el-radio :label="1">
                  75％乙醇
                </el-radio>
                <el-radio :label="2">
                  0.1％新洁尔灭
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="disinfectantMakeIssueForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="消毒剂取用量" prop="dosage">
              <el-input
                v-model="disinfectantMakeIssueForm.dosage"
                placeholder="请输入消毒剂取用量"
                clearable
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纯化水取用量" prop="usageQuantity">
              <el-input
                v-model="disinfectantMakeIssueForm.usageQuantity"
                placeholder="请输入纯化水取用量"
                clearable
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制后容量" prop="capacity">
              <el-input
                v-model="disinfectantMakeIssueForm.capacity"
                placeholder="请输入配制后容量"
                clearable
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-input
                v-model="disinfectantMakeIssueForm.validPeriod"
                placeholder="请输入有效期"
                clearable
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制人" prop="preparer">
              <el-input
                v-model="disinfectantMakeIssueForm.preparer"
                placeholder="请输入配制人"
                clearable
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="preparationDate">
              <el-date-picker
                v-model="disinfectantMakeIssueForm.preparationDate"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
                :disabled="disinfectantMakeIssueFormTitle !== '新增消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '修改消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用结果" prop="receivedResult">
              <el-radio-group
                v-model="disinfectantMakeIssueForm.receivedResult"
                :disabled="disinfectantMakeIssueFormTitle !== '领用消毒剂配制和发放记录'
                  && disinfectantMakeIssueFormTitle !== '消毒剂配制和发放记录详情'"
              >
                <el-radio :label="1">
                  已领用
                </el-radio>
                <el-radio :label="2">
                  未领用
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="disinfectantMakeIssueDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="disinfectantMakeIssueFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['DISINFECTANT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="disinfectantMakeIssuePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="配制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.makeDate">{{ scope.row.makeDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消毒剂名称">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">75％乙醇</span>
          <span v-if="scope.row.type === 2">0.1％新洁尔灭</span>
        </template>
      </el-table-column>
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="dosage" label="消毒剂取用量" />
      <el-table-column prop="usageQuantity" label="纯化水取用量" />
      <el-table-column prop="capacity" label="配制后容量" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="preparer" label="配制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.preparationDate">{{ scope.row.preparationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recipient" label="领用人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.receivedDate">{{ scope.row.receivedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="领用结果">
        <template slot-scope="scope">
          <span v-if="scope.row.receivedResult === 1">已领用</span>
          <span v-if="scope.row.receivedResult === 2">未领用</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DISINFECTANT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DISINFECTANT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['DISINFECTANT_RECEIVE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReceive(scope.$index, scope.row)"
          >
            领用
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="disinfectantMakeIssuePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDisinfectantMakeIssue,
  deleteDisinfectantMakeIssue,
  updateDisinfectantMakeIssue,
  selectDisinfectantMakeIssueInfo,
  selectDisinfectantMakeIssueList,
  receiveDisinfectantMakeIssue
} from '@/api/produce/disinfectantMakeIssue'

export default {
  data () {
    return {
      disinfectantMakeIssueDialogVisible: false,
      disinfectantMakeIssueFormTitle: '',
      disinfectantMakeIssueForm: {
        id: '',
        makeDate: '',
        type: '',
        batchNo: '',
        dosage: '',
        usageQuantity: '',
        capacity: '',
        validPeriod: '',
        preparer: '',
        preparationDate: '',
        receivedResult: '',
        status: '',
        taskId: ''
      },
      disinfectantMakeIssueFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      disinfectantMakeIssuePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      }
    }
  },
  created () {
    selectDisinfectantMakeIssueList(this.searchForm).then(res => {
      this.disinfectantMakeIssuePage = res
    })
  },
  methods: {
    disinfectantMakeIssueDialogClose () {
      this.$refs.disinfectantMakeIssueFormRef.resetFields()
    },
    disinfectantMakeIssueFormSubmit () {
      if (this.disinfectantMakeIssueFormTitle === '消毒剂配制和发放记录详情') {
        this.disinfectantMakeIssueDialogVisible = false
        return
      }
      this.$refs.disinfectantMakeIssueFormRef.validate(async valid => {
        if (valid) {
          if (this.disinfectantMakeIssueFormTitle === '新增消毒剂配制和发放记录') {
            this.disinfectantMakeIssueForm.id = ''
            this.disinfectantMakeIssueForm.status = 1
            await addDisinfectantMakeIssue(this.disinfectantMakeIssueForm)
          } else if (this.disinfectantMakeIssueFormTitle === '修改消毒剂配制和发放记录') {
            await updateDisinfectantMakeIssue(this.disinfectantMakeIssueForm)
          } else if (this.disinfectantMakeIssueFormTitle === '领用消毒剂配制和发放记录') {
            this.disinfectantMakeIssueForm.status = 2
            await receiveDisinfectantMakeIssue(this.disinfectantMakeIssueForm)
          }
          this.disinfectantMakeIssuePage = await selectDisinfectantMakeIssueList(this.searchForm)
          this.disinfectantMakeIssueDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.disinfectantMakeIssueFormTitle = '新增消毒剂配制和发放记录'
      this.disinfectantMakeIssueDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDisinfectantMakeIssue(row.id)
        if (this.disinfectantMakeIssuePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.disinfectantMakeIssuePage = await selectDisinfectantMakeIssueList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.disinfectantMakeIssueFormTitle = '修改消毒剂配制和发放记录'
      this.disinfectantMakeIssueDialogVisible = true
      this.selectDisinfectantMakeIssueInfoById(row.id)
    },
    handleReceive (index, row) {
      this.disinfectantMakeIssueFormTitle = '领用消毒剂配制和发放记录'
      this.disinfectantMakeIssueDialogVisible = true
      this.selectDisinfectantMakeIssueInfoById(row.id)
    },
    handleInfo (index, row) {
      this.disinfectantMakeIssueFormTitle = '消毒剂配制和发放记录详情'
      this.disinfectantMakeIssueDialogVisible = true
      this.selectDisinfectantMakeIssueInfoById(row.id)
    },
    selectDisinfectantMakeIssueInfoById (id) {
      selectDisinfectantMakeIssueInfo(id).then(res => {
        this.disinfectantMakeIssueForm.id = res.id
        this.disinfectantMakeIssueForm.makeDate = res.makeDate
        this.disinfectantMakeIssueForm.type = res.type
        this.disinfectantMakeIssueForm.batchNo = res.batchNo
        this.disinfectantMakeIssueForm.dosage = res.dosage
        this.disinfectantMakeIssueForm.usageQuantity = res.usageQuantity
        this.disinfectantMakeIssueForm.capacity = res.capacity
        this.disinfectantMakeIssueForm.validPeriod = res.validPeriod
        this.disinfectantMakeIssueForm.preparer = res.preparer
        this.disinfectantMakeIssueForm.preparationDate = res.preparationDate
        this.disinfectantMakeIssueForm.receivedResult = res.receivedResult
        this.disinfectantMakeIssueForm.status = res.status
        this.disinfectantMakeIssueForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDisinfectantMakeIssueList(this.searchForm).then(res => {
        this.disinfectantMakeIssuePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDisinfectantMakeIssueList(this.searchForm).then(res => {
        this.disinfectantMakeIssuePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDisinfectantMakeIssueList(this.searchForm).then(res => {
        this.disinfectantMakeIssuePage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['DISINFECTANT_RECEIVE']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
