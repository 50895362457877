import axios from '@/common/axios'
import qs from 'qs'

export function addDisinfectantMakeIssue (data) {
  return axios({
    method: 'post',
    url: '/production/disinfectantMakeIssue/add',
    data: qs.stringify(data)
  })
}

export function deleteDisinfectantMakeIssue (id) {
  return axios({
    method: 'delete',
    url: '/production/disinfectantMakeIssue/delete/' + id
  })
}

export function updateDisinfectantMakeIssue (data) {
  return axios({
    method: 'put',
    url: '/production/disinfectantMakeIssue/update',
    data: qs.stringify(data)
  })
}

export function selectDisinfectantMakeIssueInfo (id) {
  return axios({
    method: 'get',
    url: '/production/disinfectantMakeIssue/info/' + id
  })
}

export function selectDisinfectantMakeIssueList (query) {
  return axios({
    method: 'get',
    url: '/production/disinfectantMakeIssue/list',
    params: query
  })
}

export function receiveDisinfectantMakeIssue (data) {
  return axios({
    method: 'put',
    url: '/production/disinfectantMakeIssue/updateReceive',
    data: qs.stringify(data)
  })
}
